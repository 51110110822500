.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    overflow: hidden;
    overflow-y: auto;
    transition: opacity 0.3s;
    z-index: 999;
}

.modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
}

.modal-content {
    position: relative;
    margin: 20px;
    width: 100%;
    max-width: 600px;
    border-radius: 20px;
    background-color: #ffffff;
    padding: 10px 20px 20px 20px;
    transform: translateY(-50px);
    transition: opacity 0.3s, transform 0.3s;
}

.modal-close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: transparent;
}

.modal--entering,
.modal--entered {
    opacity: 1;
}

.modal--entering .modal-content,
.modal--entered .modal-content {
    transform: translateY(0);
}

.modal--exiting,
.modal--exited {
    opacity: 0;
}

.modal--exiting .modal-content,
.modal--exited .modal-content {
    transform: translateY(-50px);
}
.modal-content h2 {
    font-size: 56px;
    text-align: center;
    margin: 0;
}
.modal-content p {
    font-size: 30px; /* Установите желаемый размер шрифта для p */
    text-align: center; /* Выравнивание текста по центру */
    margin: 10px 10px; /* Отступ сверху и снизу для p, можете настроить по своему вкусу */
}

.input-group {
    margin: 20px;
    font-family: Krona One;
}

.input-group label {
    display: block;
    
    margin-bottom: 5px;
}

.input-group input {
    width: 100%;
    padding: 8px 10px;
    border-bottom: 1px solid #ccc; /* Установка границы только снизу */
    border-top: none; /* Убираем границу сверху */
    border-left: none; /* Убираем границу слева */
    border-right: none; /* Убираем границу справа */
    border-radius: 0px;
    box-sizing: border-box;
    margin-bottom: 5px;
}

.input-group input:focus {
    border-color: #4429A6;  
}

.input-group .input-hint {
    font-size: 12px;
    color: #666;
    text-align: left;
}